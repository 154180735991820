import DytePlugin from "@dytesdk/plugin-sdk";
import axios from "axios";
import React, { useEffect, useState } from 'react';

function App() {
  const [plannerDocUrl, setPlannerDocUrl] = useState(null);

  useEffect(()=>{
    intitiatedPluginFn();
  },[])

  const getMeetingData = async (roomName,role,recorder) => {
    console.log("inside getMeetiingData");
    try{
        const config = {
          url: `https://dashboard-api.preplaced.in/sessions/public/get-details-by-room-id?room_id=${roomName}`,
          method: "GET",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-type": "application/json; charset=UTF-8",
          },
        };
    
      const response = await axios(config);
      var sessionInfo = {
        candidate_id:response.data.candidate[0],
        session_record_id:response.data.recordId,
        planner_doc_url:response?.data["plannerDocLink(FromPackages)"] ? response.data["plannerDocLink(FromPackages)"][0] : "",
        session_type:response.data["sessionType(FromSessionConfiguration)"][0]
      }
  
      if(sessionInfo?.session_type === "1:1 Trial Session"){
          return setPlannerDocUrl(`https://planner.preplaced.in/trial/${sessionInfo?.session_record_id}?role=${role}&recorder=${recorder}`);
      }
      if(sessionInfo?.session_type === "1:1 Planning Session"){
        return setPlannerDocUrl(`https://planner.preplaced.in/plan/${sessionInfo?.candidate_id}?planner-doc-url=${sessionInfo?.planner_doc_url}&role=${role}`);
      }
    }catch(error){
      console.error("Error in getMeetingData",error);
    }
  };

  const intitiatedPluginFn = async () => {
    const intitiatedPlugin = await DytePlugin.init();
    const room = await intitiatedPlugin.getRoomState();
    const clientSpecificId = await intitiatedPlugin.getPeerInfo();
    var role;
    var recorder;
    if(clientSpecificId.payload.peer.clientSpecificId.split("_")[0] === "Mentor"){
      role = "mentor";
      recorder = false;
    }else if(clientSpecificId.payload.peer.clientSpecificId.split("_")[0] === "Mentee"){
      role = "mentee";
      recorder = false;
    }else if(clientSpecificId.payload.peer.clientSpecificId.split("_")[0] === "Preplaced"){
      role = 'preplaced';
      recorder = false;
    }else{
      role = "mentor";
      recorder = true;
    }
    getMeetingData(room.payload.roomName,role,recorder);
    intitiatedPlugin.emitToMeeting('intializedCodaPlugin');
  }
  return (
    <>
    {
      plannerDocUrl ? <iframe src={plannerDocUrl} style={{border: "0px"}} height="99%" width="100%" title="Planner Preplaced"></iframe> : <div>Loading your Planner doc...</div>
    }
    </>
  );
}

export default App;
